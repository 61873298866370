import React from 'react';
import { graphql } from 'gatsby';

import Page from 'components/content/user-pages/documentation';

const PISP = ({ data: { pages: { edges } } }) => <Page edges={edges} />

export default PISP;

export const query = graphql`
    query PispDocumentationQuery ($path: String!) {
        ...MarkdownPages
    }
`